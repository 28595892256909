class Resizing {
    constructor() {
        this.resizeTimer = null;
        this.resizeInterval = 50;

        this.scrollbarMeasureTimer = null;
    }

    setup() {
        const rHandler = global.utils.throttled(this.resizeInterval, this.resizeHandler);
        if(window.attachEvent) {
            window.attachEvent('onresize', rHandler);
        }else if(window.addEventListener) {
            window.addEventListener('resize', rHandler, true);
        }else {
            //The browser does not support Javascript event binding
            window.onresize = rHandler;
        }

        this.resizeHandler();
    }

    onResize(c, t) {
        onresize = function() {
            clearTimeout(t);
            t = setTimeout(c, 100);
        };
        return c;
    }

    resizeHandler() {
        global.utils.updateSizeRefs();
        //console.log(global.viewportWidth + " x " + global.viewportHeight);
        $('.resolution').html(global.viewportWidth + " x " + global.viewportHeight);

        /*if(this.scrollbarMeasureTimer !== undefined){
            clearTimeout(this.scrollbarMeasureTimer);
        }
        this.scrollbarMeasureTimer = setTimeout(global.utils.measureScrollBarWidth, this.resizeInterval*2);
        */


        if(global.viewportHeight / global.viewportWidth < 0.245){
            $('html').addClass('vp-short');
        }else{
            if(global.viewportHeight <= 500){
                $('html').addClass('vp-short');
            }else{
                $('html').removeClass('vp-short');
            }
        }

        if(global.viewportHeight / global.viewportWidth < 0.415){
            $('html').addClass('vp-letterbox');
        }else{
            $('html').removeClass('vp-letterbox');
        }

        //console.log(global.viewportHeight / global.viewportWidth);

        $('.odd-container').css('min-height',viewportHeight - $('.footer-container').outerHeight(true) - 38+'px');

        //var mH = global.utils.getMaxHeight($('.gridItem-content'),true,true);
        var mH = $('.odd-grid .gridItem-spacer').outerWidth(true) + 10;
        $('.odd-grid .gridItem').css("min-height",mH);
    }

}

module.exports = { Resizing };
