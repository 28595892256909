class InitPage {
    constructor() {
        this.pageLoaded = false;
        this.viewportScrolling = false;
        this.coloursCount = 8;
    }

    setup() {
        (async() => {
            try {

                await global.resizing.resizeHandler();
                await this.setupOddGrid();

            } catch (error) {
                console.error('(FROM ASYNC/AWAIT) Error cause is:', error);
            }
        })();

        $('body').addClass('page-loaded');
        this.pageLoaded = true;
    }

    getColour(not = null){
        var col = (Math.floor(Math.random() * (this.coloursCount - 1)) + 1);
        if(col == not){
            col++;
            if(col>this.coloursCount){
                col = 1;
            }
        }
        return col;
    }

    setupOddGrid(){

        let scope = this;

        this.shuffleDiv($('.odd-grid'));
        $('.gridItem-svg').each(function(i){
            $(this).addClass("gridSvg-"+i);
            //Set Colours
            var baseCol = scope.getColour();
            $(this).find('.baseSvg').addClass("col"+baseCol);

            var col = scope.getColour(baseCol);
            $(this).find('.topSvg').addClass("col"+col);

            //Hide unwanted shapes
            var rand = Math.floor(Math.random() * $(this).find('.baseSvg .oddSvg').length);
            $(this).find('.baseSvg .oddSvg').hide().eq(rand).show();
            $(this).find('.baseSvg .oddSvg').eq(rand).addClass('shown');
            $(this).find('.baseSvg .oddSvg').each(function(){
                if(!$(this).hasClass('shown')){
                    $(this).remove();
                }
            });

            rand = Math.floor(Math.random() * $(this).find('.topSvg .oddSvg').length);
            $(this).find('.topSvg .oddSvg').hide().eq(rand).show();
            $(this).find('.topSvg .oddSvg').eq(rand).addClass('shown');
            $(this).find('.topSvg .oddSvg').each(function(){
                if(!$(this).hasClass('shown')){
                    $(this).remove();
                }
            });

            //triagle always in front of semicircle
            if($(this).find('.baseSvg .shown').hasClass('triangle-svg') && $(this).find('.topSvg .shown').hasClass('halfOval-svg')){
                $(this).addClass('flipped');
            }

            //triagle always in front of circle
            if($(this).find('.baseSvg .shown').hasClass('triangle-svg') && $(this).find('.topSvg .shown').hasClass('oval-svg')){
                $(this).addClass('flipped');
            }

            //cross never over triangle
            if($(this).find('.baseSvg .shown').hasClass('triangle-svg') && $(this).find('.topSvg .shown').hasClass('cross-svg')){
                $(this).find('.baseSvg').hide();
            }

            //dont allow same two triangles
            if($(this).find('.baseSvg .shown').hasClass('triangle1-svg') && $(this).find('.topSvg .shown').hasClass('triangle1-svg')){
                $(this).find('.baseSvg .triangle1-svg').hide();
                $(this).find('.baseSvg .triangle2-svg').show();
            }


            //add anims
            if($(this).find('.baseSvg .shown').hasClass('triangle1-svg')){
                $(this).find('.baseSvg').addClass('reveal reveal-triangle1');
            }
            if($(this).find('.baseSvg .shown').hasClass('triangle2-svg')){
                $(this).find('.baseSvg').addClass('reveal reveal-triangle2');
            }

            if($(this).find('.topSvg .shown').hasClass('triangle1-svg')){
                $(this).find('.topSvg').addClass('reveal reveal-triangle1');
            }
            if($(this).find('.topSvg .shown').hasClass('triangle2-svg')){
                $(this).find('.topSvg').addClass('reveal reveal-triangle2');
            }
            if($(this).find('.topSvg .shown').hasClass('cross-svg')){
                $(this).find('.topSvg').addClass('reveal reveal-cross');
            }
        });

        $('.cross-svg').on('click', function(){
            window.location.reload();
        });

        setTimeout(function(){
            $('html').addClass("animDelayed");
        },1500);

    }

    shuffleDiv(el){
        var divs = el.children();
        while (divs.length) {
            el.append(divs.splice(Math.floor(Math.random() * divs.length), 1)[0]);
        }
    }
}

module.exports = { InitPage };
