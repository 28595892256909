// import jQBrowser from 'jquery.browser';
// https://github.com/webpack/webpack/issues/4039#issuecomment-273804003
const jQBrowser = require('jquery.browser');
require('lazysizes/plugins/object-fit/ls.object-fit');
const lazySizes = require("lazysizes");
require('lazysizes/plugins/parent-fit/ls.parent-fit');
require('lazysizes/plugins/bgset/ls.bgset');

class DocumentReady {
    constructor() {
    }

    setupPageClasses() {
        $('html').removeClass('no-js');

        if ("ontouchstart" in document.documentElement) {
            document.documentElement.className += ' touch';
        } else {
            document.documentElement.className += ' no-touch';
        }

        $('html').addClass(jQBrowser.name);
        $('html').addClass(jQBrowser.platform);
        if(jQBrowser.msie){
            $('html').addClass('ie');
            $('html').addClass('ie-'+parseInt(jQBrowser.version));
            $('img').css("-ms-interpolation-mode", "bicubic");
        }else{
            $('html').addClass('no-ie');
        }

        $('html').addClass('page-initial-load');

        lazySizes.init();
    }

    setupNavigationLinks() {
        //scroll to top of page on homepage logo click
        $('.logo').on('click', function(e) {
            if ($('body').hasClass('page-homepage')) {
                e.stopPropagation();
                e.preventDefault();

                global.utils.scrollToTop();

                return false;
            }
        });

        //dont reload the same page
        $('nav a').on('click', function(e) {
            if ($(this).attr('href') === window.location.href) {
                e.stopPropagation();
                e.preventDefault();

                global.utils.scrollToTop();
                $('html').removeClass('workGalleryOverlayShown');

                return false;
            }
        });
    }

    setupKeyboardEvents() {
        document.onkeydown = this.keypressHandler;
    }

    keypressHandler(e) {
        e = e || window.event;

        switch(e.keyCode){
            case '38':
                //up arrow
                break;
            case '40':
                //down arrow
                break;
            case 37:
                //left arrow
                break;
            case 39:
                //right arrow
                break;
        }
    }
}

module.exports = { DocumentReady };
